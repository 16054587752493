import * as React from 'react';
import { Link } from '@plugins/gatsby-plugin-modal-routing';
import { LABEL } from '@core/constants';
import { useDisallowAuthRoutes, useRoutes } from '@core/hooks';
import BaseHead from '@components/Head';
import ModalRoutingConsumer from '@components/auth/ModalRoutingConsumer';
import Title from '@components/auth/Title';
import Footer from '@components/auth/Footer';
import ForgotPasswordForm from '@components/auth/ForgotPasswordForm';
import { PageProps } from '@interface/common';


export default function ForgotPasswordPage({ isAuthenticating }: PageProps<any>) {
  const disallowAuthRoutes = useDisallowAuthRoutes(isAuthenticating);
  const { route, LOGIN } = useRoutes();

  return (
    <ModalRoutingConsumer
      isLoading={disallowAuthRoutes}
      render={({ modal}) => (
        <>
          <Title>{LABEL.FORGOT_PASSWORD}</Title>
          <ForgotPasswordForm/>
          <Footer>
            <p>
              {`Back to `}
              <Link
                to={route(LOGIN)}
                asModal={modal}
              >
                log in
              </Link>
              {` page.`}
            </p>
          </Footer>
        </>
      )}
    />
  );
}

export function Head() {
  return <BaseHead title={LABEL.FORGOT_PASSWORD}/>;
}
