import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import { useCaptcha } from '@core/hooks';
import * as api from '@core/api';
import { ERROR } from '@core/constants';
import Validation, { focusOnErrorDecorator, handleSubmissionError, isEmail } from '@core/forms';
import { GoogleReCaptcha } from '@components/thirdparty';
import Message from '@components/Message';
import TextInput from '@components/forms/TextInput';
import ButtonSubmit from '@components/forms/ButtonSubmit';
import { RouteComponentProps } from '@reach/router';
import { API } from '@interface/common';


const FORM_ID = 'forgot-password-form';

const schema = new Validation({
  email: {
    required: true,
    use: { isEmail },
  },
});

const focusOnError = focusOnErrorDecorator(FORM_ID);

type FormValues = API.RequestPasswordReset.Form;

export default function ForgotPasswordForm(_props: RouteComponentProps) {
  const captchaId = React.useId();
  const [isSuccess, setIsSuccess] = React.useState(false);
  const captcha = useCaptcha();

  const handleValidateForm = React.useCallback((values: FormValues) => schema.validate(values), []);

  const handleSubmitForm = React.useCallback(async (values: FormValues): Promise<any> => {
    if (!captcha.execute) {
      toast.error(ERROR.CAPTCHA_NOT_READY);
      return handleSubmissionError(ERROR.CAPTCHA_NOT_READY);
    }

    if (captcha.tokenV2) {
      try {
        await api.requestPasswordReset(values, { tokenV2: captcha.tokenV2 });
        setIsSuccess(true);
      } catch (error) {
        toast.error(error.message);
        return handleSubmissionError(error);
      }
    }

    try {
      const tokenV3 = await captcha.execute('request_reset_password');
      await api.requestPasswordReset(values, { tokenV3 });
      setIsSuccess(true);
    } catch (error) {
      captcha.reset();
      if (captcha.isLowScore(error) && !captcha.isRendered()) {
        captcha.render(captchaId);
        return false;
      } else {
        toast.error(error.message);
        return handleSubmissionError(error);
      }
    }
  }, [captcha.execute]);

  return (
    <>
      {
        isSuccess ?
          <Message>Please, check your mail for further instructions.</Message> :
          <Form
            validate={handleValidateForm}
            // @ts-ignore
            decorators={[focusOnError]}
            onSubmit={handleSubmitForm}
            render={({ handleSubmit }) => (
              <form
                id={FORM_ID}
                onSubmit={handleSubmit}
                noValidate
              >
                <Field
                  name="email"
                  type="email"
                  label="Email"
                  autoComplete="on"
                  component={TextInput}
                  required
                />
                <GoogleReCaptcha id={captchaId}/>
                <ButtonSubmit
                  label="Request reset"
                  stretch
                />
              </form>
            )}
          />
      }
    </>
  );
}
